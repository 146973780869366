import React, { useEffect, useState } from "react";
import "./App.css";
import ImageGallery from "react-image-gallery";
import ReactGa from "react-ga4";

const pages = [
  {
    original: "/menu/1.jpg",
  },
  {
    original: "/menu/2.jpg",
  },
  {
    original: "/menu/3.jpg",
  },
  {
    original: "/menu/4.jpg",
  },
  {
    original: "/menu/5.jpg",
  },
  {
    original: "/menu/6.jpg",
  },
  {
    original: "/menu/7.jpg",
  },
];

function App() {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    ReactGa.send({
      hitType: "pageview",
      page: `/page/${currentPage}`,
    });
  }, [currentPage]);

  return (
    <div className="App">
      <ImageGallery
        items={pages}
        showThumbnails={false}
        showPlayButton={false}
        autoPlay={false}
        showIndex={true}
        lazyLoad={true}
        onSlide={(index: number) => setCurrentPage(index + 1)}
      />
    </div>
  );
}

export default App;
