import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ReactGa from "react-ga4";

const TRACKING_ID = "G-5ZBS060011";

ReactGa.initialize(TRACKING_ID);

function sendToAnalytics({ id, name, value }: any) {
  ReactGa.event({
    category: "Web Vitals",
    action: name,
    value: Math.round(name === "CLS" ? value * 1000 : value),
    label: id,
    nonInteraction: true,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals(sendToAnalytics);
